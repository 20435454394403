import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	H4,
	Icon,
	IconEdit,
	IconSettings,
	IconShare,
	Paragraph,
	TextContainer,
	Ul,
} from "@siteimprove/fancylib";
import { IllustrationEducate } from "@siteimprove/fancyvisuals";
import {
	Knobs,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Example,
	Header,
	Code,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Illustration } from "../../visuals/illustration/illustration";
import {
	ContentSwitcher,
	ContentSwitcherOption,
	DefaultContentSwitcherProps,
	LargeContentSwitcherOption,
} from "./content-switcher";

export const Meta: DocPageMeta = {
	category: "Actions and controls",
	title: "Content Switcher",
	notepad: "https://hackmd.io/bejOQsGfTziQngqp3nD75w",
};

const myContentSwitcherOptions = [
	{ id: 1, label: "Option 1" },
	{ id: 2, label: "Option 2" },
	{ id: 3, label: "Option 3" },
];

export default (): JSX.Element => {
	return (
		<>
			<HeaderSection
				title="Content Switcher"
				subTitle="Content Switchers are horizontal groups of two or more buttons that allow users to switch between alternate views of related content. There is only one content section displayed at a time."
			/>
			<ContentSection>
				<TextContainer article>
					<LabWarning />
					<ImportExample lab component={ContentSwitcher} />
					<Header.H2>Examples</Header.H2>
					<Paragraph>
						Use a <Code>ContentSwitcher</Code> as a content switcher. A <Code>ContentSwitcher</Code>{" "}
						looks and functions similarly to a button group. The difference is that a{" "}
						<Code>ContentSwitcher</Code> always has one option selected by default, and only one
						option can be selected at a time. Each option contains a required text label and an
						optional icon. The large variant has a required text description - as well as the
						required text label - and can use either an icon or an illustration.
					</Paragraph>
					<Paragraph>
						A default option for a <Code>ContentSwitcher</Code> should be determined by its use.
					</Paragraph>
					<Ul
						items={[
							<>
								<H4>Unselected options:</H4>
								selectable options for each view or item.
							</>,
							<>
								<H4>Selected option:</H4>
								Only one option can be selected at a time and one should always be selected in a
								default <Code>ContentSwitcher</Code>. If you do not want to display any of the
								options selected, use the <a href="#nothing-selected">Nothing selected</a> variant.
							</>,
							<>
								<H4>Text label:</H4>
								the text label should clearly describe the content that appears below the{" "}
								<Code>ContentSwitcher</Code>.
							</>,
						]}
					/>
					<Header.H3>Default</Header.H3>
					<Example fn={BasicUsage} />
					<Header.H3>With Icons</Header.H3>
					<Example fn={UsageWithIcons} />
					<Header.H3>Large Variant</Header.H3>
					<Paragraph>
						Each option in the large variant contains a required text label, a required text
						description, and an optional icon or illustration.
					</Paragraph>
					<Example fn={LargeVariant} />
					<Header.H3>Nothing selected</Header.H3>
					<Paragraph>
						Select none of the options by explicitly giving <Code>null</Code> as value (not just{" "}
						<Code>undefined</Code>).
					</Paragraph>
					<Example fn={UsageNothingSelected} />
					<Header.H2>Properties</Header.H2>
				</TextContainer>
				<Knobs<DefaultContentSwitcherProps<ContentSwitcherOption>>
					component={ContentSwitcher}
					initialProps={({ setState }) => ({
						options: myContentSwitcherOptions,
						value: myContentSwitcherOptions[0],
						onChange: (item) => setState({ value: item }),
					})}
				/>
				<TextContainer article>
					<Header.H2>Guidelines</Header.H2>
					<Header.H3>Best practices</Header.H3>
					<InlineMessage variant="best-practices">
						<Header.H4>General</Header.H4>
						<Paragraph>
							Use <Code>ContentSwitcher</Code> when
						</Paragraph>
						<Ul
							items={[
								"the user can select only one option from a set of two to five related options or views.",
							]}
						/>
						<Header.H4>Placement</Header.H4>
						<Paragraph>
							<Code>ContentSwitcher</Code> is typically used in the following place:
						</Paragraph>
						<Ul
							items={[
								<>
									<H4>Cards</H4>
									The <Code>ContentSwitcher</Code> is often used in cards to help users display
									different options, states, or views. Be careful when writing a header for the
									card. The text label of a <Code>ContentSwitcher</Code> should stand out clearly
									from the{" "}
									<a href="https://fancy.siteimprove.com/lib/components/Structure/Card#header">
										Card header
									</a>
									. To ensure a clear hierarchy of information, do not use the{" "}
									<Code>ContentSwitcher</Code> and{" "}
									<GatsbyLink to="/lib/components/Navigation/Tabs">Tabs</GatsbyLink> in the same
									container-level component, such as{" "}
									<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>,{" "}
									<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink> or{" "}
									<GatsbyLink to="/lab/components/Overlay/Side panel">Side Panel</GatsbyLink>.{" "}
									<GatsbyLink to="/lib/components/Navigation/Tabs">Tabs</GatsbyLink> have a higher
									hierarchy level and allow navigation between groups of related content. Examples
									of <Code>ContentSwitcher</Code> in cards can be found in{" "}
									<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FAnalytics%2F91868%2FThisYear%2FUsers%2FSummary%3Flang%3Den-US">
										Analytics/Visitors/Summary
									</a>
									.
								</>,
							]}
						/>
						<Header.H4>Style</Header.H4>
						<Ul
							items={[
								"On a desktop screen, there should be no more than five to seven options, and on narrow-width interfaces, such as side panels, there should be no more than five options.",
								"An explanation for a content switcher should be provided as a header.",
								"Keep the layout consistent by placing a content switcher in the centre and above the content, if it is possible.",
								"Carefully consider where you position a content switcher with other components. When trying to switch between options, the user might accidentally click other components if they are too close.",
								"A content switcher can only have one row. Do not break down the content switcher with a second row.",
							]}
						/>
					</InlineMessage>
					<Header.H3>Do not use when</Header.H3>
					<InlineMessage variant="do-not-use-when">
						<Ul
							items={[
								<>
									the user can turn things on or off with an immediate effect. Use the{" "}
									<GatsbyLink to="/lab/components/Actions and controls/Toggle Switch">
										Toggle Switch
									</GatsbyLink>{" "}
									instead.
								</>,
								<>
									the content of the page is displayed separately without overlapping. In this case,
									use <GatsbyLink to="/lib/components/Navigation/Tabs">Tabs</GatsbyLink> instead.
								</>,
								<>
									the user can take actions, such as adding, removing, or editing content. Use the{" "}
									<GatsbyLink to="/lib/components/Actions and controls/Button">
										Button group
									</GatsbyLink>{" "}
									instead.
								</>,
								<>
									there are more than seven options. In this case, use{" "}
									<GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink>{" "}
									instead.
								</>,
							]}
						/>
					</InlineMessage>
					<Header.H3>Accessibility</Header.H3>
					<InlineMessage variant="accessibility">
						<Header.H4>For designers</Header.H4>
						<Ul
							items={[
								<>
									If possible, always provide a visible label for <Code>ContentSwitcher</Code>.
									Alternatively, provide an <Code>aria-label</Code> to describe the purpose of{" "}
									<Code>ContentSwitcher</Code>.
								</>,
								<>
									Do not include tooltips or badges in the <Code>ContentSwitcher</Code>.
								</>,
								<>
									Make sure that the switch buttons and the related content is placed immediately
									after each other so that users can easily understand the connection between them.
								</>,
								<>
									Each switch button must have a unique text label that clearly describes the
									content of the switch button. This is especially helpful for assistive technology
									users so that they have the information they need to navigate the content
									efficiently.
								</>,
							]}
						/>
						<Header.H4>For developers</Header.H4>
						<Ul
							items={[
								<>
									The <Code>ContentSwitcher</Code> has built-in accessibility. There are no
									additional requirements for developers. However, if a designer has specified an{" "}
									<Code>aria-label</Code>, that should be implemented.
								</>,
							]}
						/>
					</InlineMessage>
					<Paragraph>
						Explore detailed guidelines for this component:{" "}
						<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
							Accessibility Specifications
						</a>
					</Paragraph>
					<Header.H3>Writing</Header.H3>
					<InlineMessage variant="writing">
						<Ul
							items={[
								"Use sentence case for a text label.",
								"Keep text labels short — 1 to 3 words.",
								"Text labels should make it clear which view users will see and what content the view contains.",
							]}
						/>
					</InlineMessage>
				</TextContainer>
			</ContentSection>
		</>
	);
};

const BasicUsage = () => {
	const options: ContentSwitcherOption[] = [
		{ id: 1, label: "Option 1" },
		{ id: 2, label: "Option 2" },
		{ id: 3, label: "Option 3" },
	];

	const [state, setState] = useState(options[0]);

	return <ContentSwitcher options={options} value={state} onChange={(item) => setState(item)} />;
};

const UsageWithIcons = () => {
	const options: ContentSwitcherOption[] = [
		{
			id: 1,
			label: "Option 1",
			icon: (
				<Icon>
					<IconSettings />
				</Icon>
			),
		},
		{
			id: 2,
			label: "Option 2",
			icon: (
				<Icon>
					<IconEdit />
				</Icon>
			),
		},
		{
			id: 3,
			label: "Option 3",
			icon: (
				<Icon>
					<IconShare />
				</Icon>
			),
		},
	];

	const [state, setState] = useState(options[0]);

	return <ContentSwitcher options={options} value={state} onChange={(item) => setState(item)} />;
};

const UsageNothingSelected = () => {
	const options: ContentSwitcherOption[] = [
		{ id: 1, label: "Option 1" },
		{ id: 2, label: "Option 2" },
		{ id: 3, label: "Option 3" },
	];

	const [state, setState] = useState<ContentSwitcherOption | null>(null);

	return <ContentSwitcher options={options} value={state} onChange={(item) => setState(item)} />;
};

const LargeVariant = () => {
	const options: LargeContentSwitcherOption[] = [
		{
			id: 1,
			label: "Option 1",
			description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
		},
		{
			id: 2,
			label: "Option 2",
			icon: (
				<Icon>
					<IconEdit />
				</Icon>
			),
			description:
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fuga totam numquam obcaecati rerum placeat voluptatibus nostrum ea ipsum.",
		},
		{
			id: 3,
			label: "Option 3",
			icon: (
				<Illustration
					src={IllustrationEducate}
					size={{ width: 32, height: 32 }}
					alt="Illustration with custom size"
				/>
			),
			description:
				"Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fuga totam numquam obcaecati rerum placeat voluptatibus nostrum ea ipsum, officia nesciunt natus animi, eveniet assumenda earum. Fugiat velit eaque quae.",
		},
	];

	const [state, setState] = useState(options[0]);

	return (
		<ContentSwitcher
			options={options}
			variant="large"
			value={state}
			onChange={(item) => setState(item)}
		/>
	);
};
