import React, { ReactElement, useEffect } from "react";
import {
	DataObserveKey,
	FocusableComponent,
	VisualComponent,
	useStaticMode,
	IconProps,
	cn,
} from "@siteimprove/fancylib";
import { FormControl } from "../../forms-and-inputs/form/form";
import { KeyCode } from "../../../utils/keyboard-nav-utils";
import { BigSmall } from "../../text/big-small/big-small";
import { IllustrationProps } from "../../visuals/illustration/illustration";
import { useLabTranslations } from "../../../translations/translations";
import * as scss from "./content-switcher.scss";

export type ContentSwitcherOption = {
	/** Number for content switcher identification */
	id: number;
	/** Label for button */
	label: string;
	/** Optional Icon to render */
	icon?: ReactElement<IconProps>;
	/** Disabled state */
	disabled?: boolean;
} & DataObserveKey;

export type LargeContentSwitcherOption = ContentSwitcherOption & {
	/** Description for content switcher */
	description: string;
	/** Optional Icon or Illustration to render */
	icon?: ContentSwitcherOption["icon"] | ReactElement<IllustrationProps>;
};

type CommonContentSwitcherProps = {
	/** Execute once content switcher has been initialized */
	onInit?: (value: ContentSwitcherOption | null) => void;
	/** Specifies how content switcher looks in the static mode. Defaults to `disable`. */
	inStaticMode?: "disable" | "hide" | "show";
	/** Label of the content switcher */
	"aria-label"?: string;
	/** ID of an an element that labels this content switcher */
	"aria-labelledby"?: string;
	/** ID of an an element that describes the content switcher content */
	"aria-describedby"?: string;
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

export type DefaultContentSwitcherProps<
	T extends ContentSwitcherOption | null = ContentSwitcherOption
> = FormControl<T> &
	CommonContentSwitcherProps & {
		/** Variant */
		variant?: "default";
		/** Array of optional values for the group */
		options: ContentSwitcherOption[];
	};

export type LargeContentSwitcherProps<
	T extends LargeContentSwitcherOption | null = LargeContentSwitcherOption
> = FormControl<T> &
	CommonContentSwitcherProps & {
		/** Variant */
		variant: "large";
		/** Array of optional values for the group */
		options: LargeContentSwitcherOption[];
	};

export type ContentSwitcherProps = CommonContentSwitcherProps &
	(DefaultContentSwitcherProps | LargeContentSwitcherProps);

export function isLargeOption(option: ContentSwitcherOption): option is LargeContentSwitcherOption {
	return "description" in option;
}

export function ContentSwitcher<T extends ContentSwitcherOption | null = ContentSwitcherOption>(
	props: DefaultContentSwitcherProps<T>
): JSX.Element;
export function ContentSwitcher<
	T extends LargeContentSwitcherOption | null = LargeContentSwitcherOption
>(props: LargeContentSwitcherProps<T>): JSX.Element;
export function ContentSwitcher(props: ContentSwitcherProps): JSX.Element {
	const {
		options,
		variant,
		onChange,
		value,
		onBlur,
		id,
		onInit,
		className,
		style,
		tabIndex,
		onKeyDown,
		onMouseDown,
		onMouseEnter,
		onMouseLeave,
		onFocus,
		inStaticMode = "disable",
	} = props;

	const selectedOption = value === null ? null : value ?? options[0];
	useEffect(() => {
		if (onInit) {
			selectedOption && onInit(selectedOption);
		}
	}, []);

	const i18n = useLabTranslations();

	const isStatic = useStaticMode();
	if (isStatic && inStaticMode === "hide") {
		return <></>;
	}

	const optionRefs: HTMLButtonElement[] = [];
	const focusHandler = (event: React.FocusEvent<HTMLButtonElement>) => {
		const related = optionRefs.find((o) => o === event.relatedTarget);
		if (related) {
			related.tabIndex = -1;
		}
		event.target.tabIndex = 0;
	};

	const keyboardNavigationHandler = (event: React.KeyboardEvent<HTMLButtonElement>) => {
		const focusableOptions = optionRefs.filter((option) => !option.disabled);
		const currentIdx = focusableOptions.indexOf(event.target as HTMLButtonElement);
		const idx = keyCodeToIndex(event.keyCode, currentIdx, focusableOptions.length - 1);
		if (idx !== null) {
			event.preventDefault();
			focusableOptions[idx].focus();
		}
	};

	const optionRender = (option: ContentSwitcherOption, i: number) => {
		const selected = option.id === selectedOption?.id;
		const isDisabled = option.disabled || (isStatic && inStaticMode === "disable");

		let text;
		if (isLargeOption(option)) {
			text = <BigSmall big={option.label} small={option.description} />;
		} else {
			text = <FixedWidthText>{option.label}</FixedWidthText>;
		}

		return (
			<button
				key={option.id}
				role="radio"
				type="button"
				onClick={() =>
					isLargeOption(option)
						? (onChange as LargeContentSwitcherProps["onChange"])(option)
						: (onChange as DefaultContentSwitcherProps["onChange"])(option)
				}
				aria-checked={selected}
				disabled={isDisabled}
				className={cn(
					scss.toggleBtn,
					selected && scss.selected,
					isLargeOption(option) && scss.large
				)}
				tabIndex={i === 0 ? 0 : -1}
				onFocus={focusHandler}
				onBlur={onBlur}
				ref={(element) => element && (optionRefs[i] = element)}
				onKeyDown={keyboardNavigationHandler}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				data-observe-key={option["data-observe-key"]}
			>
				{option.icon}
				{text}
			</button>
		);
	};

	return (
		<div
			data-component="content-switcher"
			data-observe-key={props["data-observe-key"]}
			id={id}
			className={cn(
				scss.contentSwitcher,
				variant === "large" && scss.large,
				"fancy-ContentSwitcher",
				className
			)}
			role="radiogroup"
			aria-label={props["aria-label"] || i18n.contentSwitcher}
			aria-describedby={props["aria-describedby"]}
			aria-labelledby={props["aria-labelledby"]}
			style={style}
			tabIndex={tabIndex}
			onKeyDown={onKeyDown}
			onMouseDown={onMouseDown}
			onFocus={onFocus}
		>
			{options.map(optionRender)}
		</div>
	);
}

function keyCodeToIndex(keyCode: number, current: number, limit: number): number | null {
	switch (keyCode) {
		case KeyCode.ArrowLeft:
			return current > 0 ? current - 1 : limit;
		case KeyCode.ArrowRight:
			return current < limit ? current + 1 : 0;
		case KeyCode.End:
			return limit;
		case KeyCode.Home:
			return 0;
	}
	return null;
}

function FixedWidthText(props: { children: string }) {
	return (
		<span className={scss.fixedWidthText} data-text={props.children}>
			{props.children}
		</span>
	);
}
